<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="contract:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.contract"
                  placeholder="Subject of contract"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="source：" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="adv:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.advertisers"
                  placeholder=" advertisers"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="legalAdv:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.advertisersLegalName"
                  placeholder="adv LegalName"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="status:" class="mb5">
                <el-select v-model="filterForm.confirmStatus" clearable style="width: auto">
                  <el-option :key="0" :value="0" label="未完全confirm"></el-option>
                  <el-option :key="1" :value="1" label="完全confirm"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="AM" class="mb5">
                <group-select
                  v-model="filterForm.accountManage"
                  :data-source="accounts"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select AM"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="AM Group" class="mb5">
                <el-select v-model="filterForm.accountType" clearable class="w100">
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          height="780"
          size="mini"
          class="mb10"
          :row-style="{ height: '50px' }"
          @sort-change="sortChange"
        >
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column label="Operation" prop="operation" align="center"></el-table-column>
          <el-table-column label="BD" prop="business" align="center"></el-table-column>
          <el-table-column label="BD Group" prop="businessType" align="center"></el-table-column>
          <el-table-column label="AM" prop="accountManage" align="center"></el-table-column>
          <el-table-column label="AM Group" prop="accountType" align="center"></el-table-column>
          <el-table-column label="Contract" prop="contract" align="center"></el-table-column>
          <el-table-column label="Type" prop="paymentType" align="center"></el-table-column>
          <el-table-column label="Source" prop="sourceName" align="center"></el-table-column>
          <el-table-column
            label="Advertiser"
            prop="advertisers"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Advertiser Legal Name"
            prop="advertisersLegalName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="Bid Revenue" prop="bidRevenue" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.bidRevenue | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="MMP Revenue" prop="mmpRevenue" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.mmpRevenue | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Dedution1" prop="dedution1" align="center">
            <template slot="header">
              Dedution1
              <el-tooltip effect="dark" content="Bid Revenue-MMP Revenue" placement="right">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.dedution1 | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="MMP%" prop="mmpRatio" align="center">
            <template slot="header">
              MMP%
              <el-tooltip effect="dark" content="MMP Revenue/Bid Revenue" placement="right">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.mmpRatio | numberToCurrency }}
              <template v-if="!isNaN(scope.row.mmpRatio)">%</template>
            </template>
          </el-table-column>
          <el-table-column
            label="Confirm Revenue"
            prop="confirmNum"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.confirmNum | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Comfirm%" prop="mmpRatio" align="center">
            <template slot="header">
              Comfirm%
              <el-tooltip effect="dark" content="ConfirmCount/MMP Count" placement="right">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.confirmRatio | numberToCurrency }}
              <template v-if="!isNaN(scope.row.confirmRatio)">%</template>
            </template>
          </el-table-column>
          <el-table-column label="Dedution2" prop="dedution2" align="center">
            <template slot="header">
              Dedution2
              <el-tooltip effect="dark" content="MMP Revenue-Confirm Revenue" placement="right">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.dedution2 | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Dedution2%" prop="dedution" align="center">
            <template slot="header">
              Dedution2%
              <el-tooltip
                effect="dark"
                content="MMP Revenue-Confirm Revenue/MMP Revenue"
                placement="right"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.dedution | numberToCurrency
              }}<template v-if="!isNaN(scope.row.dedution)">%</template>
            </template>
          </el-table-column>
          <el-table-column
            label="Invoice Amount"
            prop="billingRevenue"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.billingRevenue | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Invoice %" prop="billingRatio" align="center">
            <template slot="header">
              Invoice %
              <el-tooltip effect="dark" content="Invoice Amount/Comfirm Revenue" placement="right">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.billingRatio | numberToCurrency
              }}<template v-if="!isNaN(scope.row.billingRatio)">%</template>
            </template>
          </el-table-column>
          <el-table-column label="Invoice Time" prop="invoiceTime" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ formatDate(scope.row.invoiceTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="The Actual Amount Received"
            prop="actualAmount"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.actualRevenue | numberToCurrency }}
            </template>
          </el-table-column>

          <el-table-column label="Received %" prop="receivedRatio" align="center">
            <template slot="header">
              Received %
              <el-tooltip
                effect="dark"
                content="The  Actual Revenue received/Billing Revenue"
                placement="right"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.receivedRatio | numberToCurrency
              }}<template v-if="!isNaN(scope.row.receivedRatio)"> %</template>
            </template>
          </el-table-column>
          <el-table-column
            label="Received Time"
            prop="receivedTime"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ formatDate(scope.row.receivedTime) }}
            </template>
          </el-table-column>
          <el-table-column label="Commission" prop="commission" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.commission | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="TAX" prop="tax" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.tax | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Unreceived" prop="unreceived" align="center">
            <template slot-scope="scope">
              {{ scope.row.unreceived | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Date of Receipt" prop="receiptDay" align="center">
            <template slot-scope="scope">
              {{ formatDate(scope.row.receiptDay) }}
            </template>
          </el-table-column>
          <el-table-column label="Delay period" prop="delayPeriod" align="center"></el-table-column>
        </el-table>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import financeSummary from '../controllers/finance/summary';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  export default {
    name: 'financeSummary',
    components: { GroupSelect },
    ...financeSummary,
  };
</script>

<style>
  .table-row {
    height: 50px;
  }
</style>
