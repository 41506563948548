import request from 'utils/request';
const api = {
  FINANCE_SUMMARY_LIST: '/api/finance/summary/list',
};

export function fetchPage(params) {
  return request({
    url: api.FINANCE_SUMMARY_LIST,
    method: 'get',
    params,
  });
}
